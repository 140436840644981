<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  إضافة حفلة</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createEvent" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم الحفلة<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label="الاسم  بالعربي"
                   v-model='event.name'
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة للعميل<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" ادخل السعر"
                   v-model='event.clientPrice'
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة للمرافق<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" ادخل السعر"
                   v-model='event.companionPrice'
                    required
                  />
                </div>
                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      event: {},
      errors: []
    }
  },

  methods: {
    async createEvent (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.eventName)) {
        swal('اسم الحفلة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.event.name)
      formData.append('client_price', this.event.clientPrice)
      formData.append('companion_price', this.event.companionPrice)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'events', '/events')
    }
  }
}
</script>
<style >
.error{
  color:#f44335 !important;
  font-size:13px;
   padding-right: 25px;
}
.unrequired{
    display: flex;
    gap: 5px;
}
.li-h{
  line-height:87px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
 rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    padding: 20px !important;
    margin-top: 26px !important;
    margin-left: unset !important
}

</style>
